<template>
  <CV />
</template>

<script lang="ts">
import CV from '@/components/CV.vue';

export default {
  components: {
    CV,
  },
};
</script>
